import Cookies from "js-cookie";

export default {
  namespaced: true,
  state: {
    userInfo: {},
  },
  mutations: {
    // SET_STATE: (state, data) => {
    //   Object.keys(data).forEach(key => {
    //     state[key] = data[key]
    //   })
    // },
  },
  actions: {
    // USER_INFO: ({ commit }) => {
    //   commit('SET_STATE', { userInfo: JSON.parse(Cookies.get('userInfo')) })
    // },
  }
}