import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'
import configCommon from '@/utils/configCommon'
import NProgress from 'nprogress'
import Cookies from 'js-cookie'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: () => import('@/views/index'),
      },
      {
        path: '/:username?',
        component: () => import('@/views/info'),
        meta: {
          title: ''
        }
      },
      {
        path: '/legal/privacy-policy',
        component: () => import('@/views/legal/privacy-policy'),
        meta: {
          title: '隱私政策'
        }
      },
      {
        path: '/legal/terms-of-service',
        component: () => import('@/views/legal/terms-of-service'),
        meta: {
          title: '使用條款'
        }
      },
      {
        path: '/user/profile',
        component: () => import('@/views/user/profile'),
        meta: {
          title: '個人資料'
        }
      },
      {
        path: '/user/privacy-and-security',
        component: () => import('@/views/user/privacy-and-security'),
        meta: {
          title: '隱私設定和帳號安全'
        }
      },
      {
        path: '/user/notifications',
        component: () => import('@/views/user/notifications'),
        meta: {
          title: '推播通知'
        }
      },
      {
        path: '/user/password-free-signin',
        component: () => import('@/views/user/password-free-signin'),
        meta: {
          title: '免密碼登入'
        }
      },
      {
        path: '/user/signin',
        component: () => import('@/views/user/signin'),
        meta: {
          title: '登入'
        }
      },
      {
        path: '/user/signup',
        component: () => import('@/views/user/signup'),
        meta: {
          title: '註冊'
        }
      },
      {
        path: '/user/email-verify',
        component: () => import('@/views/user/email-verify'),
        meta: {
          title: '電子郵件驗證'
        }
      },
      {
        path: '/user/forgot-password',
        component: () => import('@/views/user/forgot-password'),
        meta: {
          title: '忘記密碼'
        }
      },
      {
        path: '/user/change-password',
        component: () => import('@/views/user/change-password'),
        meta: {
          title: '修改密碼'
        }
      },
      {
        path: '*',
        component: () => import('@/views/error'),
        meta: {
          title: '系統錯誤'
        }
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth'
    }
  },
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title ? `${to.meta.title} - ` : ''}${configCommon.webTitle}`
  NProgress.start()

  if (Cookies.get("authorization")) {
    if (to.path === '/login') {
      next({ path: '/' })
      return
    }
  }
  NProgress.done()
  next()
})

export default router