import Vue from 'vue'
import app from '@/App.vue'
import router from '@/router'
import store from '@/store'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import Animate from 'animate.css'
import VueMeta from 'vue-meta'
import '@/utils/fb-sdk'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.less'
import '@/utils/errorHandler'
import "@/utils/dialogDrag";
import '@/components/index'
import '@/styles/nprogress.css'
// import '@/icons'

Vue.use(ElementUI, { locale })
Vue.use(Animate)
Vue.use(VueMeta)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(app),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')