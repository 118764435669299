<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import configCommon from "@/utils/configCommon";
import { getIPAddress } from "@/api/ip";
import Cookies from 'js-cookie'

export default {
  mixins: [mainCommon],
  mounted() {
    window.addEventListener("beforeunload", this.handleLeave);
  },
  updated() {
    this.handleVisit();
  },
  beforeCreate() {
    getIPAddress().then((res) => {
      Cookies.set("ip", res.ip);
    });
  },
  methods: {
    handleVisit() {
      this.HandleRouteHistory(location.href, "visited");
    },
    handleLeave() {
      this.HandleRouteHistory(location.href, "leave");
    },
  },
  metaInfo() {
    return {
      link: [
        {
          rel: "mask-icon",
          href: require("@/assets/logo0709.png"),
          color: "#000000",
        },
      ],
      meta: [
        // {
        //   name: "keywords",
        //   content: this.HandleLang("system.keywords"),
        // },
        // {
        //   name: "description",
        //   content: `${this.HandleLang("system.webTitle")} ${this.HandleLang(
        //     "system.description"
        //   )}`,
        // },
        // {
        //   property: "og:title",
        //   content: this.HandleLang("system.webTitle"),
        // },
        // {
        //   property: "og:description",
        //   content: `${this.HandleLang("system.webTitle")} ${this.HandleLang(
        //     "system.description"
        //   )}`,
        // },
        // {
        //   property: "og:site_name",
        //   content: this.HandleLang("system.webTitle"),
        // },
        // {
        //   property: "og:image",
        //   content: require("@/assets/logo0709.png"),
        // },
        // {
        //   property: "og:type",
        //   content: "website",
        // },
        {
          name: "robots",
          content: location.href.includes(configCommon.productionHost)
            ? "index, follow"
            : "unindex, unfollow",
        },
      ],
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500&family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap");
</style>