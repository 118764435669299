const _webDomainDev = 'outsource.theingdesign.com'
const _webDomainProd = 'outsource.com'

const _apiDomainLocalhost = 'http://localhost:8201'
const _apiDomainDev = 'https://outsource-dev-api.theingdesign.com'
const _apiDomainProd = 'https://outsource-api.theingdesign.com'

export default {
  webTitle: 'Gualooa',
  productionHost: _webDomainProd,
  isProduction: location.host.includes(_webDomainProd),
  apiHost: location.host.includes(_webDomainProd)
    ? _apiDomainProd
    : (
      location.host.includes(_webDomainDev)
        ? _apiDomainDev
        : _apiDomainLocalhost
    ),
  deviceWidth: 500,
  waterfallWidth: 550,
  waterfallHeight: 300,
  waterfallLineGap: 0,
  loadingDataTimeout: 500,
  workCardScrollBottomHeight: 100,
  workCardPageSize: 6,
}