import request from '@/utils/_request'

export function getIPAddress() {
  return request({
    url: `https://api.ipify.org?format=json`,
    method: `get`,
  })
}

export function getIPLocation() {
  return request({
    url: `https://ipinfo.io?token=42ebc6a604bce2`,
    method: `get`,
  })
}