import CryptoJS from 'crypto-js'
import '@/utils/jsencrypt.min'

const JSE = new JSEncrypt();
JSE.setPublicKey('-----BEGIN PUBLIC KEY-----' +
  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4lAu1mpUZw8aqYPHGaXQcrBJo+jXArD7pW670XY7RumMAHmKhz6PXqnoIn2dpa+8imTs5Ji8z5SbcCkQgi/xgqsAMQD1VMPcvoz2T8czfTviD1UTfyjdqSQgFa30yuIc8/upy2M91dj5OYgWNENoC52N30Ag9EDPDAoKhChY+xJDcpF9qONMiu+0+RvGh8lDNynYfCPEmtodXsgsP+g9puWApMZi8+nNYVT2VTKd8A+yvalmPjP031BpMHnLbWqXZneXFmYDTEcXa5zXG8QF0Nt/F89iDAHb4abHcYbspKsqrMVkPcWI3L8Kh2P0qT0qLzASNBy+H/RWenMpoQuxHwIDAQAB' +
  '-----END PUBLIC KEY-----');
const ENCRYPT_KEY = generateRandomKey(32);
const ENCRYPT_IV = generateRandomKey(16);
let key = CryptoJS.enc.Utf8.parse(ENCRYPT_KEY);
let iv = CryptoJS.enc.Utf8.parse(ENCRYPT_IV);

export function encryptData(data) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv,
    mode: CryptoJS.mode.CBC,
    // padding: CryptoJS.pad.Pkcs7,
  }).toString();
}

export function decryptData(data) {
  return JSON.parse(CryptoJS.AES.decrypt(data, key, {
    iv,
    mode: CryptoJS.mode.CBC,
  }).toString(CryptoJS.enc.Utf8))
}

// var key = CryptoJS.MD5(ENCRYPT_KEY)
// key = CryptoJS.enc.Utf8.parse(key)

// export function decryptData(data) {
//   console.log(key)
//   console.log(CryptoJS.AES.decrypt(
//     data,
//     key,
//     {
//       mode: CryptoJS.mode.ECB
//     }
//   ).toString(CryptoJS.enc.Utf8));
// }

// export function decryptData(data, key) {
//   return JSON.parse(
//     CryptoJS.AES.decrypt(
//       getBase64String(data),
//       CryptoJS.enc.Utf8.parse(
//         JSE.decrypt(getBase64String(key), false)
//       ),
//       {
//         mode: CryptoJS.mode.ECB,
//         padding: CryptoJS.pad.Pkcs7
//       }
//     ).toString(CryptoJS.enc.Utf8)
//   );
// }

// export function encryptData(data) {
//   return getURLSafeString(
//     CryptoJS.AES.encrypt(
//       JSON.stringify(data),
//       CryptoJS.enc.Utf8.parse(ENCRYPT_KEY),
//       {
//         mode: CryptoJS.mode.ECB,
//         padding: CryptoJS.pad.Pkcs7
//       }
//     ).toString()
//   );
// }

export function getEncryptSecret() {
  return ENCRYPT_KEY;
}

export function getEncryptIV() {
  return ENCRYPT_IV;
}

// function getURLSafeString(str) {
//   return str
//     .replace(/\+/g, '-')
//     .replace(/\//g, '_');
// }

// function getBase64String(str) {
//   return str
//     .replace(/-/g, '+')
//     .replace(/_/g, '/');
// }

function generateRandomKey(len) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}