<template>
  <el-dialog
    top="10vh"
    width="400px"
    center
    title="意見反應"
    :visible="feedbackObj.visible"
    :close-on-click-modal="false"
    @close="HandleCancel()"
  >
    <el-form label-position="top">
      <el-form-item label="如何稱呼你">
        <template v-if="userInfo.name"> {{ userInfo.name }}</template>
        <el-input
          v-else
          clearable
          v-model.trim="dataObj.name"
          maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item label="電子郵件">
        <template v-if="userInfo.email"> {{ userInfo.email }}</template>
        <el-input
          v-else
          clearable
          v-model.trim="dataObj.email"
          maxlength="100"
        ></el-input>
      </el-form-item>
      <el-form-item label="意見或反應內容">
        <el-input
          type="textarea"
          :autosize="{ minRows: 5, maxRows: 8 }"
          v-model="dataObj.content"
          maxlength="300"
          show-word-limit
        ></el-input>
        <ShowTitle
          text="請詳細填寫你的意見或反應內容，我們將會透過電子郵件回覆。"
          type="field-sub-title"
        />
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <el-button
        class="submit-btn"
        :loading="submitLoading"
        @click="HandleSubmit()"
      >
        送出</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import { slackHandler } from "@/utils/slack";

export default {
  props: ["feedbackObj"],
  mixins: [mainCommon],
  data() {
    return {
      submitLoading: false,
      dataObj: { name: null, email: null, content: null },
    };
  },
  methods: {
    HandleSubmit() {
      this.dataObj.name = this.userInfo.name;
      this.dataObj.email = this.userInfo.email;
      if (!this.dataObj.name) {
        return this.pushNotification("我們該如何稱呼你？", "error");
      }
      if (!this.dataObj.email) {
        return this.pushNotification("請輸入你的電子郵件", "error");
      }
      if (!this.dataObj.content) {
        return this.pushNotification("請輸入意見或反應內容", "error");
      }
      this.submitLoading = true;
      slackHandler(
        `a new feedback just received.`,
        "outsource-feedback",
        [
          {
            color: "#2ec9d3", // 2ec9d3 | f77d7d | 36a64f
            fields: [
              {
                title: "Name",
                value: this.dataObj.name,
                short: true,
              },
              {
                title: "Email",
                value: this.dataObj.email,
                short: true,
              },
              {
                title: "Content",
                value: this.dataObj.content,
                short: false,
              },
            ],
          },
        ],
        true
      );
      setTimeout(() => {
        this.pushNotification("你的反饋已送出，我們將盡快回復你。");
        this.submitLoading = false;
        this.HandleCancel();
      }, 500);
    },
    HandleCancel() {
      this.dataObj = { name: null, email: null, content: null };
      this.$emit("update:feedbackObj", {});
    },
  },
};
</script>

<style lang="less" scoped>
</style>