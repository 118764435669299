import Vue from 'vue'
import Cookies from 'js-cookie'
import { slackHandler } from '@/utils/slack'
import DeviceDetector from 'device-detector-js'

const deviceDetector = new DeviceDetector()
const device = deviceDetector.parse(navigator.userAgent)

// Vue.config.errorHandler = function (err, vm, info) {
//   slackHandler(`an error just received from ${location.host}.`, 'vue-error', [{
//     color: '#f77d7d', // 2ec9d3 | f77d7d | 36a64f
//     fields: [
//       {
//         title: 'Browser',
//         value: `${device.client.name} (Version: ${device.client.version})`,
//         short: true
//       },
//       {
//         title: 'Device',
//         value: `${device.device.brand || 'Unknown'} (Type: ${device.device.type || 'Unknown'})`,
//         short: true
//       },
//       {
//         title: 'OS',
//         value: `${device.os.name} (Version: ${device.os.version || 'Unknown'})`,
//         short: true
//       },
//       {
//         title: 'IP',
//         value: Cookies.get('ip'),
//         short: true
//       },
//       {
//         title: 'Route',
//         value: location.href,
//         short: true
//       },
//       {
//         title: 'Error',
//         value: err.toString(),
//         short: false
//       },
//       {
//         title: 'Info',
//         value: info,
//         short: false
//       },
//     ]
//   }], true)
// }

// Vue.config.warnHandler = function (err, vm, trace) {
//   slackHandler(`a warn just received from ${location.host}.`, 'vue-warn', [{
//     color: '#f77d7d', // 2ec9d3 | f77d7d | 36a64f
//     fields: [
//       {
//         title: 'Browser',
//         value: `${device.client.name} (Version: ${device.client.version})`,
//         short: true
//       },
//       {
//         title: 'Device',
//         value: `${device.device.brand || 'Unknown'} (Type: ${device.device.type || 'Unknown'})`,
//         short: true
//       },
//       {
//         title: 'OS',
//         value: `${device.os.name} (Version: ${device.os.version || 'Unknown'})`,
//         short: true
//       },
//       {
//         title: 'IP',
//         value: Cookies.get('ip'),
//         short: true
//       },
//       {
//         title: 'Route',
//         value: location.href,
//         short: true
//       },
//       {
//         title: 'Error',
//         value: err
//       },
//       {
//         title: 'Trace',
//         value: trace
//       },
//     ]
//   }], true)
// }