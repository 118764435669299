<template>
  <div class="navbar-container">
    <el-row>
      <el-col :span="12">
        <router-link to="/">Gualooa</router-link>
      </el-col>
      <el-col :span="12" style="text-align: right">
        <div v-if="!authorization">
          <router-link
            class="ease-in-out-03s signin-btn basic-hover"
            to="/user/signin"
          >
            登入
          </router-link>
          <router-link class="ease-in-out-03s signup-btn" to="/user/signup">
            註冊
          </router-link>
        </div>
        <div v-if="authorization">
          <el-dropdown>
            <span class="el-dropdown-link pointer">
              <el-avatar
                fit="cover"
                :src="userInfo.avatar"
                icon="el-icon-user-solid"
                size="small"
              ></el-avatar>
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/user/profile">
                <el-dropdown-item>我的帳戶</el-dropdown-item>
              </router-link>
              <router-link to="/user/change-password">
                <el-dropdown-item divided> 修改密碼 </el-dropdown-item>
              </router-link>
              <el-dropdown-item>
                <el-button
                  class="ease-in-out-03s signout-btn margin-tb-xs"
                  @click="signOut()"
                >
                  登出
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";

export default {
  mixins: [mainCommon],
  methods: {
    signOut() {
      this.HandleSignOut();
      setTimeout(() => {
        location.href = "/";
      }, 500);
    },
  },
};
</script>

<style lang="less" scoped>
.signout-btn {
  width: 120px;
  padding: 9px 20px;
  background: #f7734a;
  color: #fff;
  border-radius: 3px;
  outline: none;
  border: 0;
  // -webkit-appearance: button;
  &:hover {
    background: #ca5532;
    color: #fff;
  }
}
.signin-btn {
  font-size: 13px;
  padding: 7px 20px;
  color: #767676;
}
.signup-btn {
  font-size: 13px;
  border-radius: 5px;
  color: #fff;
  padding: 7px 20px;
  background: #eee;
  color: #767676;
  &:hover {
    background: #f7734a;
    color: #fff;
  }
}
</style>