<template>
  <div>
    <router-link
      v-for="item in menuList"
      :key="item.label"
      class="ease-in-out-03s basic-hover btn"
      :to="item.to"
    >
      {{ item.label }}
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          label: "個人資料",
          to: "/user/profile",
        },
        {
          label: "推播通知",
          to: "/user/notifications",
        },
        {
          label: "隱私設定和帳號安全",
          to: "/user/privacy-and-security",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.btn {
  color: #999;
  display: block;
  padding: 0 0 25px 0;
  &:hover {
    color: #000;
  }
}
.router-link-exact-active {
  color: #000;
  font-weight: 600;
}
</style>