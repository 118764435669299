import axios from 'axios'
import { Notification } from 'element-ui'
import Cookies from "js-cookie"
import configCommon from '@/utils/configCommon'
import methodCommon from '@/utils/methodCommon'
import { encryptData, decryptData, getEncryptSecret, getEncryptIV } from '@/utils/encrypt'
// import NProgress from 'nprogress'

const req = axios.create({
  baseURL: configCommon.apiHost,
  timeout: 15000,
  withCredentials: true,
})

req.interceptors.request.use(request => {
  if (localStorage.getItem("authorization") && Cookies.get("authorization")) {
    request.headers["authorization"] = `${localStorage.getItem("authorization")}${Cookies.get("authorization")}`
  }

  request.headers['secret'] = getEncryptSecret();
  request.headers['iv'] = getEncryptIV();

  if (request.data) {
    request.headers['content-type'] = 'application/json;charset=UTF-8';
    request.data = encryptData(request.data);
  }
  return request
}, error => {
  return Promise.reject(error)
})

req.interceptors.response.use(response => {
  if (response.data.code !== 200) {
    Notification.warning({
      title: '系統警告',
      message: response.data.message,
    });
  }
  if (response.data.code === 40000) {
    methodCommon.methods.HandleSignOut();
    setTimeout(() => {
      location.href = "/";
    }, 2000);
  }

  if (response.data.data) {
    response.data.data = decryptData(response.data.data)
  }

  return response.data
}, error => {
  Notification.error({
    title: '系統錯誤',
    message: error.response
      ? error.response.data.message
      : '很抱歉，伺服器出現了問題。系統已聯絡管理員，請稍候再試',
  });
  return Promise.reject(error)
})

export default req