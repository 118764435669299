import Cookies from 'js-cookie'
import { slackHandler } from '@/utils/slack'
import configCommon from '@/utils/configCommon'
import DeviceDetector from 'device-detector-js'
import { Loading } from 'element-ui';

const deviceDetector = new DeviceDetector()
const device = deviceDetector.parse(navigator.userAgent)

export default {
  methods: {
    pushNotification(message, type = 'success') {
      let title = '系統通知'
      switch (type) {
        case 'error':
          title = '系統錯誤'
          type = 'error'
          break;
        case 'warning':
          title = '系統警告'
          type = 'error'
          break;
      }
      return this.$notify({
        title,
        message,
        type,
      });
    },
    HandleAuthorized() {
      if (this.userInfo && this.userInfo.token) {
        this.$router.push({ path: '/' })
      }
    },
    HandleSignOut() {
      Loading.service({
        lock: true,
        // text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      localStorage.removeItem("authorization");
      Cookies.remove("authorization");
      // Cookies.set("userInfo", {});
    },
    HandleRouteOpen(to) {
      this.HandleRouteHistory(to, 'opened')
      window.open(to)
    },
    HandleRouteHistory(to, action, previewTitle = '') {
      let fields = [
        {
          title: 'Browser',
          value: `${device.client.name} (Version: ${device.client.version})`,
          short: true
        },
        {
          title: 'Device',
          value: `${device.device.brand || 'Unknown'} (Type: ${device.device.type || 'Unknown'})`,
          short: true
        },
        {
          title: 'OS',
          value: `${device.os.name} (Version: ${device.os.version || 'Unknown'})`,
          short: true
        },
        {
          title: 'IP',
          value: Cookies.get('ip'),
          short: true
        },
        {
          title: 'Route',
          value: location.href,
          short: false
        },
      ]
      if (action === 'preview')
        fields.push({
          title: 'Preview Title',
          value: previewTitle,
          short: false
        })
      slackHandler(`${Cookies.get('ip')} ${action} ${to}.`, configCommon.isProduction ? 'studio-route-history-prod' : 'studio-route-history-dev', [{
        color: '#2ec9d3', // 2ec9d3 | f77d7d | 36a64f
        fields: fields
      }])
    },
    // HandleRouteState(u) {
    //   window.history.pushState('', '', u)
    // },
    // HandleWindowScrollListener(isListen, func) {
    //   if (isListen)
    //     return window.addEventListener('scroll', func)
    //   return window.removeEventListener('scroll', func)
    // },
    // HandleShuffle(i) {
    //   i.sort(() => {
    //     return Math.random() - 0.5
    //   })
    // },
    // HandleGetState(m, k) {
    //   return eval(`this.$store.state.${m}.${k}`)
    // },
    // HandleSetState(m, o) {
    //   this.$store.commit(m, o)
    // },
    // CapitalizeFirstLetter(s) {
    //   return s.charAt(0).toUpperCase() + s.slice(1)
    // },
  },
}