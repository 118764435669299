<template>
  <div :class="basicClass">
    {{ text }}
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";

export default {
  mixins: [mainCommon],
  props: {
    text: {
      require: true,
    },
    type: {
      require: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    basicClass() {
      switch (this.type) {
        case "title":
          return "text-xxl text-center title";
        case "field-sub-title":
          return "text-xs font-ccc";
        case "footer-title":
          return "text-df font-gray text-bold padding-bottom-xs";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  padding: 0 0 30px 0;
}
</style>