<template>
  <div class="footer-container">
    <div class="footer-content">
      <el-row>
        <el-col :span="6">
          <ShowTitle text="法律與政策" type="footer-title" />
          <router-link
            v-for="item in legalList"
            :key="item.label"
            class="ease-in-out-03s basic-hover btn"
            :to="item.to"
          >
            {{ item.label }}
          </router-link>
        </el-col>
        <el-col :span="6">
          <ShowTitle text="支援" type="footer-title" />
          <el-link
            @click="openFeedback()"
            :underline="false"
            class="ease-in-out-03s basic-hover btn"
            >意見反應</el-link
          >
          <!-- <router-link
            v-for="item in supportList"
            :key="item.label"
            class="ease-in-out-03s basic-hover btn"
            :to="item.to"
          >
            {{ item.label }}
          </router-link> -->
        </el-col>
        <el-col :span="6">1</el-col>
        <el-col :span="6">1</el-col>
      </el-row>

      <div class="footer-copyright">Gualooa © 2022</div>
    </div>

    <Feedback :feedbackObj.sync="feedbackObj" />
  </div>
</template>

<script>
import Feedback from "./Feedback";

export default {
  data() {
    return {
      feedbackObj: {},
      legalList: [
        {
          label: "隱私政策",
          to: "/legal/privacy-policy",
        },
        {
          label: "使用條款",
          to: "/legal/terms-of-service",
        },
      ],
      supportList: [],
    };
  },
  components: {
    Feedback,
  },
  methods: {
    openFeedback() {
      this.feedbackObj = {
        visible: true,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.footer-copyright {
  margin: 10px 0;
}
.btn {
  font-size: 13px;
  color: #999 !important;
  display: block;
  padding: 7px 0;
  &:hover {
    color: #000 !important;
  }
}
.router-link-exact-active {
  color: #000;
}

.footer-container {
  background: #fbfbfb;
  padding: 30px 0;
}
.footer-content {
  width: 1100px;
  margin: 0 auto;
}

@media screen and (max-width: 659px) {
  .footer-content {
    width: 90%;
    margin: 20px auto;
  }
}
</style>