<template>
  <div>
    <Header v-if="!navHide" />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <Footer v-if="!navHide" />

    <div v-if="!cookieAgree">
      <div class="cookie-hits cookie-hits-fade">
        Gualooa 使用 cookie
        是為您提供我們網站上的最佳用戶體驗。它們還幫助我們了解我們的網站是如何被使用的。您繼續使用我們的網站服務即表示您同意本網站使用您的
        cookie。
        <i @click="HandleClose()" class="el-icon-close cookie-close"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";
import mainCommon from "@/utils/mainCommon";
import Cookies from "js-cookie";
import { userProfile } from "@/api/user";

export default {
  mixins: [mainCommon],
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      navHide: false,
      cookieAgree: Cookies.get("cookieAgree") || false,
    };
  },
  watch: {
    $route(to) {
      this.HandleNavHide(to);
    },
  },
  created() {
    this.HandleNavHide(this.$route);
    if (this.$route.path !== "/user/profile") {
      userProfile().then((res) => {
        this.$store.state.user.userInfo = res.data;
      });
    }
  },
  methods: {
    HandleClose() {
      Cookies.set("cookieAgree", true);
      this.cookieAgree = true;
    },
    HandleNavHide(to) {
      this.navHide =
        to.path === "/user/signin" ||
        to.path === "/user/password-free-signin" ||
        to.path === "/user/signup" ||
        to.path === "/user/change-password" ||
        to.path === "/user/forgot-password" ||
        to.path === "/user/email-verify"
          ? true
          : false;
    },
  },
};
</script>

<style lang="less" scoped>
.cookie-hits-fade {
  animation: cookieHitsFade 1s forwards;
}
@keyframes cookieHitsFade {
  from {
    opacity: 0;
    margin-bottom: -60px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
.cookie-close {
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  position: absolute;
  right: 0;
  margin-right: 20px;
}
.cookie-hits {
  z-index: 999;
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  font-size: 12px;
  color: #fff;
}
</style>