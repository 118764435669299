import configCommon from '@/utils/configCommon'
import Cookies from "js-cookie"
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
    authorization() {
      return `${localStorage.getItem("authorization") || ''}${Cookies.get("authorization") || ''}`
    },
    deviceShow() {
      return 'device-show'
    },
    desktopShow() {
      return 'desktop-show'
    },
    fadeIn() {
      return 'animate__animated animate__fadeIn'
    },
    deviceMode() {
      return window.innerWidth < configCommon.deviceWidth ? true : false
    },
    windowWidth() {
      return window.innerWidth
    },
  },
}