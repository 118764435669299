<template>
  <div>
    <template v-if="string"> {{ string }}</template>
    <template v-else>
      <i class="el-icon-loading" />
    </template>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";

export default {
  mixins: [mainCommon],
  props: {
    string: {
      require: true,
    },
  },
};
</script>

<style lang="less" scoped>
</style>