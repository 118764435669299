import request from '@/utils/request'

export function userEmailVerifyResend(data) {
  return request({
    url: '/api/user/email-verify-resend',
    method: 'post',
    data: data,
  })
}

export function userAvatarChange(data) {
  return request({
    url: '/api/user/avatar-change',
    method: 'post',
    data: data,
  })
}

export function userPasswordChange(data) {
  return request({
    url: '/api/user/password-change',
    method: 'post',
    data: data,
  })
}

export function userPasswordReset(data) {
  return request({
    url: '/api/user/password-reset',
    method: 'post',
    data: data,
  })
}

export function userEmailVerify(data) {
  return request({
    url: '/api/user/email-verify',
    method: 'post',
    data: data,
  })
}

export function userList() {
  return request({
    url: '/api/user/list',
    method: 'get',
  })
}

export function userInfo(username) {
  return request({
    url: `/api/user/info/${username}`,
    method: 'get',
  })
}

export function userEdit(data) {
  return request({
    url: '/api/user/edit',
    method: 'post',
    data: data,
  })
}

export function userProfile() {
  return request({
    url: '/api/user/profile',
    method: 'get',
  })
}

export function userSignin(data) {
  return request({
    url: '/api/user/signin',
    method: 'post',
    data: data,
  })
}

export function userSignup(data) {
  return request({
    url: '/api/user/signup',
    method: 'post',
    data: data,
  })
}